import React from 'react';

import Image from 'components/image';


const deliveryVan = 'delivery-van.png';

const P24101 = () => (
  <>
    <div>
      <h3>
        Designação do projeto
      </h3>
      <p>
        <b>
          YOUSHIP
        </b>
      </p>
    </div>
    <div>
      <h3>
        Código do projeto
      </h3>
      <p>
        CENTRO-02-0752-FEDER-024101
      </p>
    </div>
    <div>
      <h3>
        Objetivo principal
      </h3>
      <p>
        OT 3 - Reforçar a competitividade das PME
      </p>
    </div>
    <div>
      <h3>
        Região de intervenção
      </h3>
      <p>
        Centro
      </p>
    </div>
    <div>
      <h3>
        Entidade beneficiária
      </h3>
      <p>
        SHIPNOW TECHNOLOGIES, UNIPESSOAL LDA
      </p>
    </div>
    <div>
      <h3>
        Data de aprovação
      </h3>
      <p>
        2017-08-08
      </p>
    </div>
    <div>
      <h3>
        Data de início
      </h3>
      <p>
        2017-06-15
      </p>
    </div>
    <div>
      <h3>
        Data de conclusão
      </h3>
      <p>
        2020-06-14
      </p>
    </div>
    <div>
      <h3>
        Custo total elegível
      </h3>
      <p>
        107.464,38 EUR
      </p>
    </div>
    <div>
      <h3>
        Apoio financeiro da União Europeia
      </h3>
      <p>
        FEDER 48.358,97 EUR
      </p>
    </div>
    <div>
      <h3>
        O projecto YOUSHIP pretende facilitar o envio de encomendas, promovendo o transporte sustentável e a melhoria da qualidade de vida nas cidades.
      </h3>
      <section className="col-12 col-md-4 p-0">
        <Image
          alt="Delivery Van"
          src={deliveryVan}
        />
      </section>
    </div>
  </>
);

export default P24101;
